<template>
      <router-view />

</template>

<script>
export default {

  name: 'App',

  components: {
  },

  data: () => ({
    //
  }),
  mounted() {
    // // this.$root.$i18n.locale = this.$route.params.locale;
    // document.documentElement.style.setProperty('--animate-duration', '3s');
  },
}
</script>
